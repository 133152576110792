import { useState } from "react";

import { useTranslation } from "src/translations/translationProvider";

import Button from "src/components/button";
import ControlHeader from "src/components/controlHeader/controlHeader";

import globalStyles from "src/styles/global.module.scss";
import styles from "./cogenerationPlan.module.scss";

import { ExcelValidationErrors } from "src/components/excelValidationError/excelValidationError";
import {
  CogenerationData,
  CogenerationPlanInput,
  ParsedExcelError,
  ValidateParsedCogenerationPlanExcel,
} from "src/modules/app/customerdata/cogenerationplans/validateCogenerationPlan";
import { parseExcel } from "src/utilities/parseExcel";

export function CogenerationPlan() {
  const { translate } = useTranslation();

  const [plans, setPlans] = useState<CogenerationPlanInput[]>();
  const [errors, setErrors] = useState<ParsedExcelError[]>();
  const [canSave, setCanSave] = useState(false);

  const handleSave = () => {
    console.log("Saving cogenerationplans", plans);
  };

  const handleFileChange = (file: File) => {
    parseExcel<CogenerationData>(file, (result) => {
      const { errors, values } = ValidateParsedCogenerationPlanExcel(result);
      console.log(errors, values);
      if (errors.length > 0) {
        setErrors(errors);
        setCanSave(false);
      } else {
        setPlans(values);
        setCanSave(true);
      }
    });
  };

  return (
    <div>
      <header className={globalStyles.subTitle}>
        {translate("AvailabilityPlanDownloadInstructions")}
      </header>
      <ControlHeader className={styles.controlHeader}>
        <div className={styles.fileUpload}>
          <input
            type="file"
            name="cogenerationPlan"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!file) {
                return;
              }
              handleFileChange(file);
            }}
          ></input>
        </div>
      </ControlHeader>
      <main className={styles.verification}>
        <div className={globalStyles.sectionTitleLarge}></div>
        <ExcelValidationErrors errors={errors || []} />
      </main>
      <Button disabled={!canSave} onClick={handleSave} small>
        {translate("Save")}
      </Button>
    </div>
  );
}

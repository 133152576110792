import { parseISO } from "date-fns";
import { LanguageKey } from "src/config/translations/translationsTypes";
import xlsx from "xlsx";

export function parseExcel<T>(
  excelFile: File,
  setParsedExcel: (arg: ParsedExcel<T>) => void,
) {
  if (excelFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0] || "";
      const worksheet = workbook.Sheets[sheetName];
      if (!worksheet) {
        // TODO proper error here
        console.log("No worksheet found");
        return;
      }
      const json: ParsedExcel<T>["data"] = xlsx.utils.sheet_to_json(worksheet, {
        defval: null,
        header: "A",
      });
      const jsonHeaders = json.shift();
      setParsedExcel({ data: json, header: jsonHeaders });
    };

    reader.readAsArrayBuffer(excelFile);
  }
}

export function isNumber(value: string | number) {
  if (typeof value === "number") {
    return true;
  }
  return !isNaN(Number(value)) && value.trim() !== "";
}

export function isValidDate(dateString: string | null) {
  if (dateString === null) {
    return false;
  }
  const date = parseISO(dateString);
  return !isNaN(date.getTime());
}

export function ValidateParsedExcel<T, Result>(
  parsedExcel: ParsedExcel<T>,
  validateRowAndCreateErrors: (row: T) => Result | ParsedExcelError[],
): {
  errors: ParsedExcelError[];
  values: Result[];
} {
  return parsedExcel.data.reduce(
    ({ errors, values }, row) => {
      const value = validateRowAndCreateErrors(row);
      if (isParsedExcelErrors(value)) {
        return {
          errors: [...errors, ...value],
          values,
        };
      }
      return {
        errors,
        values: [...values, value],
      };
    },
    {
      errors: [],
      values: [],
    } as {
      errors: ParsedExcelError[];
      values: Result[];
    },
  );
}

function isParsedExcelErrors<T>(
  input: T | ParsedExcelError[],
): input is ParsedExcelError[] {
  return Array.isArray(input);
}

export interface ParsedExcel<T> {
  data: T[];
  header: T | undefined;
}

export interface ParsedExcelError {
  column: string;
  row: number;
  value: string | null;
  message: LanguageKey;
}

import React, { ReactNode } from "react";
import classNames from "classnames";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  LogoIcon,
  HomeIcon,
  InvoicesIcon,
  KnowledgeIcon,
  AdminIcon,
  AdminKeyIcon,
  EnergyIcon,
  NetmwLogoIcon,
  PortfolioIcon,
  ContactIcon,
  DeveloperIcon,
} from "src/icons";

import Avatar from "src/components/avatar";
import Menu from "src/components/menu";
import HashLink from "src/components/hashLink";
import { ReadAccessControl } from "src/components/accessControl";
import { useTranslation } from "src/translations/translationProvider";

import styles from "./navBar.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";
import { MenuLink } from "src/components/navBar/MenuLink";
import { CustomerDataIcon } from "src/icons/CustomerDataIcon";

export function NavBar({ children }: { children: ReactNode }) {
  const currentUser = useGetCurrentUser();
  const { translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function closeMenu() {
    location.hash = "";
    navigate(location, { replace: true });
  }

  return (
    <div className={styles.wrapper}>
      <nav className={styles.nav}>
        <NavLink
          end
          to="/"
          className={styles.link}
          title={translate("Menu_Start")}
        >
          <LogoIcon className="icon" />
        </NavLink>

        <MenuLink
          end
          to="/"
          title={translate("Menu_Start")}
          data-test-id="menu-start-icon"
        >
          <HomeIcon className="icon" />
        </MenuLink>

        <MenuLink
          to="/files"
          title={translate("Menu_Files")}
          data-test-id="menu-files-icon"
        >
          <InvoicesIcon className="icon" />
        </MenuLink>

        <ReadAccessControl restrictionTypes={["Knowledge"]}>
          <MenuLink
            to="/knowledge"
            title={translate("Menu_Knowledge")}
            data-test-id="menu-knowledge-icon"
          >
            <KnowledgeIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl restrictionTypes={["Energy"]}>
          <MenuLink
            to="/energy"
            title={translate("Menu_Energy")}
            data-test-id="menu-energy-icon"
          >
            <EnergyIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl restrictionTypes={["ModityAdmin"]}>
          <MenuLink
            to="/customerdata"
            title={translate("Menu_CustomerData")}
            data-test-id="menu-energy-icon"
          >
            <CustomerDataIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl
          operator={"or"}
          restrictionTypes={["PortfolioPhysical", "PortfolioFinancial"]}
        >
          <MenuLink to="/portfolio">
            <PortfolioIcon
              className="icon"
              title={translate("Menu_Portfolio")}
              data-test-id="menu-portfolio-icon"
            />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl restrictionTypes={["Administration"]}>
          <MenuLink
            to="/admin"
            title={translate("Menu_Settings")}
            data-test-id="menu-admin-icon"
          >
            <AdminIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl restrictionTypes={["ModityAdmin"]}>
          <MenuLink
            to="/modity_admin"
            title={translate("Menu_ModitySettings")}
            data-test-id="menu-modity-admin-icon"
          >
            <AdminKeyIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <ReadAccessControl restrictionTypes={["Developer"]}>
          <MenuLink
            to="/developer"
            title={translate("Menu_Developer")}
            data-test-id="menu-developer-icon"
          >
            <DeveloperIcon className="icon" />
          </MenuLink>
        </ReadAccessControl>

        <div className={styles.bottomLinks}>
          <HashLink
            hash="#contact"
            data-test-id="menu-contact-icon"
            activeClassName={styles.contactActive}
            className={styles.link}
          >
            <ContactIcon className={classNames("icon", styles.large)} />
          </HashLink>

          <ReadAccessControl restrictionTypes={["NetMW"]}>
            <MenuLink
              to="/netMW"
              title={translate("Menu_NetMW")}
              data-test-id="menu-netMW-icon"
            >
              <NetmwLogoIcon
                className={classNames("icon", styles.large, styles.netMwLogo)}
              />
            </MenuLink>
          </ReadAccessControl>

          <HashLink
            hash="#user"
            activeClassName={styles.activeHash}
            className={classNames(styles.hashLink, styles.link)}
            data-test-id="menu-user-icon"
          >
            <Avatar
              imageUrl={currentUser.avatar && currentUser.avatar.fileUrl}
              type="large"
            />
          </HashLink>
        </div>
      </nav>

      <Menu
        user={currentUser}
        isOpen={window.location.hash === "#user"}
        close={closeMenu}
      >
        {children}
      </Menu>
    </div>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import config from "src/config/config";

import { OrganizationAppQuery } from "src/components/appQuery";
import Author from "src/components/author";
import Hero from "src/components/hero";
import RadioButtonList from "src/components/radioButtonList";
import TabBar from "src/components/tabBar";
import SplitLayout from "src/components/splitLayout";
import { PageLayout } from "src/components/pageLayout/pageLayout";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import ArticleList from "./articleList.jsx";
import { GET_FEATURED_ARTICLE } from "src/services/apollo/queries";
import { isMobile, NotMobile, Mobile } from "src/components/responsive";
import ArticlePreview from "../../components/articlePreview";

import styles from "./knowledge.module.scss";
import adminStyles from "src/styles/admin.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";
import { Search } from "src/components/search/search";

const getImageObjectOrDefault = (img) => {
  const defaultImg = { fileUrl: config.defaultHeroImage };
  return img
    ? {
        desktop: (img.desktop || defaultImg).fileUrl,
        tablet: (img.tablet || defaultImg).fileUrl,
        mobile: (img.mobile || defaultImg).fileUrl,
      }
    : {
        desktop: defaultImg.fileUrl,
        tablet: defaultImg.fileUrl,
        mobile: defaultImg.fileUrl,
      };
};

const LoadingFeaturedArticle = () => (
  <div
    className={classNames(
      styles.featuredArticleLink,
      styles.loadingFeaturedArticle,
    )}
  >
    <div className={styles.loadingFeaturedArticleHeader}></div>
  </div>
);

const Knowledge = ({
  filterOptions,
  searchInput,
  typeFilter,
  onFilter,
  onSearch,
}) => {
  const currentUser = useGetCurrentUser();
  const { translate } = useTranslation();
  const [search, setSearch] = useState(searchInput);

  const decoratedOnFilter = (newFilter) => {
    onFilter(newFilter, search);
  };

  const renderLoading = () => {
    return (
      <div className={styles.featuredArticleContainer}>
        <ArticlePreview className={styles.article} />
      </div>
    );
  };

  return (
    <SplitLayout
      mainContent={
        <PageLayout
          title={
            searchInput ? (
              <>
                <Translate>Search results for:</Translate>{" "}
                <strong>&quot;{searchInput}&quot;</strong>
              </>
            ) : (
              <Translate>Knowledge Center</Translate>
            )
          }
          subtitle={<Translate>Everything you need to know</Translate>}
        >
          <NotMobile>
            {!searchInput && (
              <OrganizationAppQuery
                query={GET_FEATURED_ARTICLE}
                variables={{
                  organizationId:
                    currentUser.selectedOrganizationConnection.organization.id,
                }}
                renderLoading={LoadingFeaturedArticle}
                renderError={LoadingFeaturedArticle}
              >
                {({ organization: { featuredArticle } }) => {
                  if (!featuredArticle) {
                    return null;
                  }
                  return (
                    <Link
                      className={styles.featuredArticleLink}
                      to={`/article/${featuredArticle.id}`}
                    >
                      <Hero
                        className={styles.hero}
                        title={featuredArticle.title}
                        responsiveImage={getImageObjectOrDefault(
                          featuredArticle.heroImage,
                        )}
                        preamble={featuredArticle.preamble}
                        featured={true}
                      >
                        <Author
                          className={styles.featuredArticleAuthor}
                          {...featuredArticle.author}
                          publishedDate={featuredArticle.publishedDate}
                          large
                        />
                      </Hero>
                    </Link>
                  );
                }}
              </OrganizationAppQuery>
            )}
            <Search
              className={classNames(styles.search, styles.restrictContentWidth)}
              placeholder={translate("Looking for anything particular?")}
              defaultValue={searchInput}
              key={`${typeFilter}${searchInput}`}
              onChange={setSearch}
              onSubmit={onSearch}
              autoFocus={!isMobile()}
            />
            <RadioButtonList
              className={styles.filter}
              titleClassName={styles.filterTitle}
              optionClassName={styles.filterOption}
              activeOptionClassName={styles.activeFilterOption}
              title={translate("Showing:")}
              options={filterOptions}
              selectedValue={typeFilter}
              onChange={decoratedOnFilter}
            />
          </NotMobile>
          <Mobile>
            <div className={styles.searchWrapper}>
              <Search
                className={classNames(adminStyles.search, styles.search)}
                buttonClassName={adminStyles.searchButton}
                placeholder={translate("Search")}
                defaultValue={searchInput}
                onChange={setSearch}
                onSubmit={onSearch}
              />
              {searchInput && (
                <div className={styles.searchResultsLabel}>
                  <Translate>Search results for:</Translate>{" "}
                  <strong>&quot;{searchInput}&quot;</strong>
                </div>
              )}
            </div>
            <TabBar
              options={filterOptions}
              onChange={decoratedOnFilter}
              selectedValue={typeFilter}
            />
            {!searchInput && typeFilter === "all" && (
              <OrganizationAppQuery
                query={GET_FEATURED_ARTICLE}
                renderLoading={renderLoading}
              >
                {({ featuredArticle } = {}) => {
                  if (featuredArticle) {
                    const { id, contentType, ...rest } = featuredArticle;
                    return (
                      <div className={styles.featuredArticleContainer}>
                        <Link
                          className={styles.link}
                          key={id}
                          to={`/article/${id}`}
                        >
                          <ArticlePreview key={id} isFeatured {...rest} />
                        </Link>
                      </div>
                    );
                  }
                  return null;
                }}
              </OrganizationAppQuery>
            )}
          </Mobile>
          <ArticleList searchInput={searchInput} typeFilter={typeFilter} />
        </PageLayout>
      }
    />
  );
};

Knowledge.propTypes = {
  currentPageNumber: PropTypes.number,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  searchInput: PropTypes.string,
  typeFilter: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};

export default Knowledge;

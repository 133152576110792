import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
    ref={ref}
  >
    <path d="M21.9 19.071 14.827 12 21.9 4.929 19.071 2.1 12 9.17l-7.071-7.07L2.1 4.929 9.17 12l-7.07 7.071L4.929 21.9 12 14.83l7.071 7.07z" />
  </svg>
);

const ForwardRef = forwardRef(SvgClose);
const Memo = memo(ForwardRef);
export { Memo as CloseIcon };

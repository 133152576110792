import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgAdd = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z" />
  </svg>
);
const ForwardRef = forwardRef(SvgAdd);
const Memo = memo(ForwardRef);
export { Memo as AddIcon };

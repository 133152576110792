import React, { ReactNode } from "react";
import classNames from "classnames";

import { NotMobile, Mobile } from "src/components/responsive";
import NotIframe from "src/components/notIframe";

import styles from "./pageLayout.module.scss";
import globalStyles from "src/styles/global.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";

export function PageLayout({
  className,
  children,
  title,
  subtitle,
}: {
  className?: string;
  children?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
}) {
  const currentUser = useGetCurrentUser();
  const activeOrganization =
    currentUser.selectedOrganizationConnection.organization.name;
  return (
    <div
      className={classNames(
        globalStyles.pageContentWrapper,
        styles.pageLayout,
        className,
      )}
    >
      <header>
        <NotIframe>
          <NotMobile>
            <h1
              data-test-id="welcome-text"
              className={classNames(globalStyles.pageTitle, styles.pageTitle)}
            >
              {title}
            </h1>
            <div className={styles.organizationName}>{activeOrganization}</div>
            <div className={globalStyles.subTitle}>{subtitle}</div>
          </NotMobile>
        </NotIframe>
        <Mobile>
          <div className={styles.pageTitle}>
            <h1 className={globalStyles.pageTitle}>{title}</h1>
          </div>
        </Mobile>
      </header>
      {children}
    </div>
  );
}

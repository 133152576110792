import { PlanInput } from "src/graphql/graphql";
import {
  isNumber,
  isValidDate,
  ParsedExcel,
  ParsedExcelError,
  ValidateParsedExcel,
} from "src/utilities/parseExcel";

export function ValidateAvailabilityExcel(
  parsedExcel: ParsedExcel<AvailabilityData>,
): {
  errors: ParsedExcelError[];
  values: PlanInput[];
} {
  return ValidateParsedExcel(parsedExcel, validateRowAndCreateErrors);
}

function validateRowAndCreateErrors(
  row: ParsedExcel<AvailabilityData>["data"][0],
): PlanInput | ParsedExcelError[] {
  const errors: ParsedExcelError[] = [];
  if (!isValidDate(row["A"])) {
    errors.push({
      column: "A",
      row: row.__RowNum__,
      value: row["A"],
      message: "invalid date format",
    });
  }
  if (!isValidDate(row["B"])) {
    errors.push({
      column: "B",
      row: row.__RowNum__,
      value: row["B"],
      message: "invalid date format",
    });
  }
  if (row.C != null && !isNumber(row.C)) {
    errors.push({
      column: "C",
      row: row.__RowNum__,
      value: row.C.toString(),
      message: "invalid number value",
    });
  }
  if (row["D"] != null && !isNumber(row["D"])) {
    errors.push({
      column: "D",
      row: row.__RowNum__,
      value: row["D"].toString(),
      message: "invalid number value",
    });
  }
  if (row["E"] != null && !isNumber(row["E"])) {
    errors.push({
      column: "E",
      row: row.__RowNum__,
      value: row["E"].toString(),
      message: "invalid number value",
    });
  }

  if (errors.length === 0) {
    return {
      fromTimestamp: row.A as PlanInput["fromTimestamp"],
      toTimestamp: row.B as PlanInput["toTimestamp"],
      power: row.C === null ? null : Number(row.C),
      curtailment: row.D === null ? null : Number(row.D),
      ice: row.E === null ? null : Number(row.E),
      comment: row.F,
    };
  }

  return errors;
}

export type AvailabilityData = {
  A: string | null;
  B: string | null;
  C: number | string | null;
  D: number | string | null;
  E: number | string | null;
  F: string | null;
  __RowNum__: number;
};

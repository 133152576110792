import { Helmet } from "react-helmet";
import { PageLayout } from "src/components/pageLayout/pageLayout";
import { CogenerationPlan } from "src/modules/app/customerdata/cogenerationplans/cogenerationPlan";
import { useTranslation } from "src/translations/translationProvider";
export function CustomerData() {
  const { translate } = useTranslation();

  return (
    <section>
      <Helmet>
        <title>{`Modity - ${translate("Menu_CustomerData")}`}</title>
      </Helmet>
      <PageLayout title={translate("Heading_CustomerData")}>
        <CogenerationPlan />
      </PageLayout>
    </section>
  );
}

import { LanguageKey } from "src/config/translations/translationsTypes";
import {
  isNumber,
  isValidDate,
  ParsedExcel,
  ValidateParsedExcel,
} from "src/utilities/parseExcel";

export function ValidateParsedCogenerationPlanExcel(
  parsedExcel: ParsedExcel<CogenerationData>,
): {
  errors: ParsedExcelError[];
  values: CogenerationPlanInput[];
} {
  return ValidateParsedExcel(parsedExcel, validateRowAndCreateErrors);
}

function validateRowAndCreateErrors(
  row: ParsedExcel<CogenerationData>["data"][0],
): CogenerationPlanInput | ParsedExcelError[] {
  const errors: ParsedExcelError[] = [];
  if (!isValidDate(row["A"])) {
    errors.push({
      column: "A",
      row: row.__RowNum__,
      value: row["A"],
      message: "invalid date format",
    });
  }
  if (!isValidDate(row["B"])) {
    errors.push({
      column: "B",
      row: row.__RowNum__,
      value: row["B"],
      message: "invalid date format",
    });
  }
  if (row.C != null && !isNumber(row.C)) {
    errors.push({
      column: "C",
      row: row.__RowNum__,
      value: row.C.toString(),
      message: "invalid number value",
    });
  }
  if (row["D"] != null && !isNumber(row["D"])) {
    errors.push({
      column: "D",
      row: row.__RowNum__,
      value: row["D"].toString(),
      message: "invalid number value",
    });
  }

  if (errors.length === 0) {
    return {
      fromTimestamp: row.A as CogenerationPlanInput["fromTimestamp"],
      toTimestamp: row.B as CogenerationPlanInput["toTimestamp"],
      production: row.C === null ? null : Number(row.C),
      consumption: row.D === null ? null : Number(row.D),
    };
  }

  return errors;
}

export interface ParsedExcelError {
  column: string;
  row: number;
  value: string | null;
  message: LanguageKey;
}

export interface CogenerationPlanInput {
  fromTimestamp: string;
  toTimestamp: string;
  production: number | null;
  consumption: number | null;
}

export type CogenerationData = {
  A: string | null;
  B: string | null;
  C: number | string | null;
  D: number | string | null;
  __RowNum__: number;
};

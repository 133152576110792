import { useTranslation } from "src/translations/translationProvider";
import styles from "./excelValidationError.module.scss";
import { ParsedExcelError } from "src/utilities/parseExcel";

export function ExcelValidationErrors({
  errors,
}: {
  errors: ParsedExcelError[];
}) {
  const { translate } = useTranslation();

  if (errors.length <= 0) {
    return null;
  }

  return (
    <div>
      <h3>{translate("ExcelValidationError")}</h3>
      {errors.map((error, i) => (
        <div className={styles.error} key={i}>
          {translate("row")}: {error.row + 1},&nbsp;
          {translate("column")}: {error.column},&nbsp;
          {translate("value")}: {error.value},&nbsp;
          {translate("error message")}: {translate(error.message)}
        </div>
      ))}
    </div>
  );
}

import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M5 2v28h22V2H5zm20 26H7V4h18v24zM15 6H9v6h6V6zm-2 4h-2V8h2v2zm10 0h-7V8h7v2zm-8 3H9v6h6v-6zm-2 4h-2v-2h2v2zm10 0h-7v-2h7v2zm-8 3H9v6h6v-6zm-2 4h-2v-2h2v2zm10 0h-7v-2h7v2z" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as CustomerDataIcon };

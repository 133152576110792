import classNames from "classnames";
import styles from "./navBar.module.scss";
import { NavLink, NavLinkProps, useNavigate } from "react-router-dom";

export function MenuLink({ to, children, ...props }: NavLinkProps) {
  const navigate = useNavigate();
  return (
    <NavLink
      to={to}
      onClick={(e) => {
        e.preventDefault();
        if (!document.startViewTransition) {
          return navigate(to);
        } else {
          return document.startViewTransition(() => {
            navigate(to);
          });
        }
      }}
      className={({ isActive }) =>
        classNames(styles.link, {
          [`${styles.active}`]: isActive,
        })
      }
      {...props}
    >
      {children}
    </NavLink>
  );
}
